<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <!--输入框-->

      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('update') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                background-color: #2362FB; border-color: #2362FB"
            @click="handleEdit('edit')"
            >编辑
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('add') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                background-color: #2362FB; border-color: #2362FB"
            @click="handleEdit('add')"
            >新增
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('delete') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                background-color: #2362FB; border-color: #2362FB"
            @click="handleEdit('del')"
            >删除
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('update_table') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>

        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('reset_query') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>

        <!--对应第三方图标的button样式-->
        <!--<div class="search-container-fn-input">
                      <el-button size="mini" type="primary" icon="el-icon-plus"
                                           style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                background-color: #2362FB; border-color: #2362FB"
                                           @click="handleAddRecord">创建档案
                      </el-button>
                  </div>-->
      </div>
    </div>
    <!--		<div class="search-container">
                      <div class="search-container-fn-input">
                          <label>建档日期：</label>
                          <el-date-picker
                              v-model="choiceDateCreateRecord"
                              :default-time="['00:00:00', '23:59:59']"
                              type="daterange"
                              size="mini"
                              value-format="yyyy-MM-dd HH-mm-ss "
                              range-separator="至"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              @change="handleCreateRecordChangeDate">
                          </el-date-picker>
                      </div>
                      <div class="search-container-fn-input">
                          <label>到期日期：</label>
                          <el-date-picker
                              v-model="choiceDateExpire"
                              :default-time="['00:00:00', '23:59:59']"
                              type="daterange"
                              size="mini"
                              value-format="yyyy-MM-dd HH-mm-ss "
                              range-separator="至"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              @change="handleExpireChangeDate">
                          </el-date-picker>
                      </div>
                  </div>-->
    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="625"
      style="width: 100%;z-index:0"
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <el-table-column type="selection" width="55" align="center" />
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <!--<template #header>
                          <el-popover placement="bottom" title="" width="230" trigger="hover">
                              <div slot="reference" class="search-header">
                                  <span class="search-title">{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
                                  <i style="margin-left: 5px" class="search-icon el-icon-search"></i>
                              </div>
                              <el-input
                                      size="mini"
                                      placeholder=""
                                      prefix-icon="el-icon-search"
                                      v-model="searchParams.buy_count"
                                      clearable
                                      @change="getList('restPage')"
                                  >
                                  </el-input>
                          </el-popover>
                      </template>-->
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="handleCustomerDetail(scope.row)"
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="
            column.field_type === 'imageBtn' && column.visible === true
          "
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              v-if="scope.row[column.column_prop].length > 0"
              style="font-size: 12px; color: #2379fb"
              @click="
                handleShowImage(
                  scope.row[column.column_prop],
                  scope.row[column.column_prop][0]
                )
              "
            >
              <a>点击查看图片</a>
              <el-image
                style="width: 0px; height: 0px"
                :ref="scope.row[column.column_prop][0]"
                :src="imageUrl"
                :preview-src-list="scope.row[column.column_prop]"
                :z-index="9999"
              >
              </el-image>
            </div>
            <div v-else>
              暂无
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-input
                v-if="'order_count' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.buy_count"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.buy_count)
                "
              >
              </el-input>

              <el-select
                v-else-if="'is_wx' == column.column_prop"
                style="width:200px"
                v-model="searchParams.is_wx"
                placeholder="请选择"
                size="mini"
                clearable
                @change="handleSearch(column.column_prop, searchParams.is_wx)"
              >
                <template slot="prefix">
                  <i
                    class="el-icon-search"
                    style="width: 25px; line-height: 32px;"
                  ></i>
                </template>
                <el-option
                  v-for="item in optionsWX"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>

              <el-select
                v-else-if="'visit_level' == column.column_prop"
                style="width:200px"
                v-model="searchParams.visit_level"
                placeholder="请选择"
                size="mini"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.visit_level)
                "
              >
                <template slot="prefix">
                  <i
                    class="el-icon-search"
                    style="width: 25px; line-height: 32px;"
                  ></i>
                </template>
                <el-option
                  v-for="item in optionsVisitLevel"
                  :key="item.short_name"
                  :label="item.short_name"
                  :value="item.short_name"
                >
                </el-option>
              </el-select>

              <el-date-picker
                v-else-if="'created_at' == column.column_prop"
                v-model="choiceDateCreateRecord"
                :default-time="['00:00:00', '23:59:59']"
                type="daterange"
                size="mini"
                value-format="yyyy-MM-dd HH-mm-ss "
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleCreateRecordChangeDate()"
              >
              </el-date-picker>

              <el-date-picker
                v-else-if="'expired_at' == column.column_prop"
                v-model="choiceDateExpire"
                :default-time="['00:00:00', '23:59:59']"
                type="daterange"
                size="mini"
                value-format="yyyy-MM-dd HH-mm-ss "
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleExpireChangeDate"
              >
              </el-date-picker>

              <el-date-picker
                v-else-if="'end_visit_at' == column.column_prop"
                v-model="choiceDateEndVisit"
                :default-time="['00:00:00', '23:59:59']"
                type="daterange"
                size="mini"
                value-format="yyyy-MM-dd HH-mm-ss "
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleEndVisitChangeDate"
              >
              </el-date-picker>

              <el-date-picker
                v-else-if="'end_order_at' == column.column_prop"
                v-model="choiceDateEndOrder"
                :default-time="['00:00:00', '23:59:59']"
                type="daterange"
                size="mini"
                value-format="yyyy-MM-dd HH-mm-ss "
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleEndOrderChangeDate"
              >
              </el-date-picker>
              <el-date-picker
                v-else-if="'next_visit_at' == column.column_prop"
                v-model="choiceDateNextVisit"
                :default-time="['00:00:00', '23:59:59']"
                type="daterange"
                size="mini"
                value-format="yyyy-MM-dd HH-mm-ss "
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleNextVisitChangeDate"
              >
              </el-date-picker>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
          <template slot-scope="scope">
            <span v-if="column.column_prop === 'buy_status'">{{
              scope.row[column.column_prop] === 1 ? "未购" : "已购"
            }}</span>

            <span v-else-if="column.column_prop === 'is_wx'">
              {{ scope.row[column.column_prop] == "0" ? "否" : "是" }}
            </span>
            <span v-else-if="column.column_prop === 'supervise_status'">
              {{ scope.row[column.column_prop] | formatSuperviseStatus }}
            </span>
            <span v-else>{{ scope.row[column.column_prop] }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!----------------分页---------------->
    <!--<pagination :total="total" :page.sync="listQuery.page" -->
    <!--						:limit.sync="listQuery.limit"-->
    <!--						@pagination="getList"/>-->

    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />

    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      :table_type="table_type"
    >
    </editTableHead>
    <el-dialog :visible.sync="dialogVisible" width="30%" :show-close="false">
      <span slot="title" style="border-bottom:2px solid #2362FB"
        >{{ type == "add" ? "新增" : "编辑" }}短信</span
      >
      <div class="row">
        <div style="width:80px;">名称：</div>
        <el-input v-model="form.name" placeholder="请输入名称" class="input" size="mini"></el-input>
      </div>
      <div class="row" style="align-items: flex-start !important;">
        <div style="width:80px;">内容：</div>
        <el-input type="textarea" v-model="form.content"  :rows="4" placeholder="请输入内容" class="input" size="mini"></el-input>
      </div>
      <div class="row">
        <div style="width:80px;">签名：</div>
        <el-input v-model="form.sign_name" placeholder="请输入签名" class="input" size="mini"></el-input>
        <div></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="_ok" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination/index";
import editTableHead from "@/components/editTableHead";
import { mapState } from "vuex";
import { getAdminFieldIndex } from "@/api/ggkq";
import {
  smsList,
  delSmsTemplate,
  updateSmsTemplate,
  addSmsTemplate,
  smsOptions,
} from "@/api/note";

export default {
  name: "noteTemplate",
  components: {
    Pagination,
    editTableHead,
  },
  computed: {
    ...mapState({
      is_admin: (state) => state.user.is_admin,
      operates: (state) => state.user.operates,
      table_options: state => state.user.table_options,
    }),
  },
  async created() {
    console.log(this.$route.path);
    console.log(this.operates, 12312312);
    this.operates.forEach((operate) => {
      if (operate[this.$route.path]) {
        console.log(operate[this.$route.path].split(","));
        this.operateList.push(...operate[this.$route.path].split(","));
      }
    });
    await this._getAdminFieldIndex();
    this._smsOptions();
    this.getList();
  },
  data() {
    return {
      table_type:'noteTemplate',
      searchTableHead: [
        {
          name: "order_count",
          isSearch: false,
        },
      ],
      tableHeadSortable: ["created_at"],
      isActiveEditTableHead: false,
      tableHead: [],
      dialogVisible: false,
      searchParams: {},

      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      multipleSelection: [],
      total: 0,
      listLoading: false,
      tableData: [],
      operateList: [],
      options: [],
      form: {
        name: "",
        content:'',
        sign_name:'',
      },
      type: "add",
    };
  },
  methods: {
    //短信模板选项
    _smsOptions() {
      smsOptions().then((response) => {
        let data = response;
        if (data.code == 200) {
          this.options = data.data;
        }
      });
    },
    handleSearch(prop, value) {
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleEdit(type) {
        this.type  = type;
      if (type == "del") {
        if (this.multipleSelection.length == 0) {
          this.$message({
            message: "请选择要删除的模板",
            type: "warning",
          });
          return false;
        }
        this.$confirm("此操作将永久删除该模板, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let id = this.multipleSelection[0].id
            delSmsTemplate( id ).then((response) => {
              let data = response;
              if (data.code == 200) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.getList("restPage");
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else if (type == "edit") {
        if (this.multipleSelection.length == 0) {
          this.$message({
            message: "请选择要编辑的模板",
            type: "warning",
          });
          return false;
        }
        if (this.multipleSelection.length > 1) {
          this.$message({
            message: "只能选择一个模板进行编辑",
            type: "warning",
          });
          return false;
        }
        this.form.id = this.multipleSelection[0].id;
        this.form.sign_name = this.multipleSelection[0].sign_name;
        this.form.content = this.multipleSelection[0].content;
        this.form.name = this.multipleSelection[0].name;
        this.dialogVisible = true;
      } else {
        this.dialogVisible = true;
      }
    },
    //queren提交
    _ok() {
      if (this.type == "add") {
        addSmsTemplate(this.form).then((response) => {
          let data = response;
          if (data.code == 200) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.getList("restPage");
            this.dialogVisible = false;
            this.form = {
              name: "",
              content:'',
              sign_name:'',
            };
          }
        });
      } else {
        console.log(this.form)
        updateSmsTemplate(this.form.id,this.form).then((response) => {
          let data = response;
          if (data.code == 200) {
            this.$message({
              message: "编辑成功",
              type: "success",
            });
            this.getList("restPage");
            this.dialogVisible = false;
          }
        });
      }
    },
    handleResetSearch() {
      this.searchParams = {
        type: "buy",
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "noteTemplate",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.noteTemplate;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      val.forEach((item) => {
        this.multipleSelection.push(item);
      });
    },
    getList(type) {
      if (type == "restPage") {
        this.listQuery.page = 1;
      }
      console.log("getList触发");

      this.listLoading = true;
      this.searchParams.page = this.listQuery.page;
      this.searchParams.limit = this.listQuery.limit;
      smsList(this.searchParams)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = _data.per_page;
            this.tableData = _data.data;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.isSearch {
  color: #f56c6c;
}
.search-icon {
  color: #f56c6c;
}
.row{
    display: flex;
    align-items: center;
    margin-top:20px;
}
.input{
    width:200px;
}
</style>
