import request from '@/utils/request'
//短信模板

//新增短信模板
export function addSmsTemplate(data) {
    return request({
        url: `/admin/addSmsTemplate`,
        method: 'POST',
        data
    })
}
//删除短信模板
export function delSmsTemplate(id) {
    return request({
        url: `/admin/delSmsTemplate/${id}`,
        method: 'delete',
    })
}
//修改短信模板
export function updateSmsTemplate(id,data) {
    return request({
        url: `/admin/updateSmsTemplate/${id}`,
        method: 'PUT',
        data
    })
}
//短信模板列表
export function smsList(params) {
    return request({
        url: `/admin/smsList`,
        method: 'GET',
        params
    })
}
//短信模板选项
export function smsOptions() {
    return request({
        url: `/admin/smsOptions`,
        method: 'GET',
    })
}
//发送短信
export function sendMessage(data) {
    return request({
        url: `/admin/sendMessage`,
        method: 'POST',
        data
    })
}
//群发短信
export function sendGroupMessage(data) {
    return request({
        url: `/admin/sendGroupMessage`,
        method: 'POST',
        data
    })
}